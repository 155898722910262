import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LanguageProvider } from "../contexts/Language";
import { UserProvider } from "../contexts/User";
import NavBar from "../common_components/navBar/index";
import Footer from "../common_components/footer/index";
import Home from "../pages/home/index";
import Restaurant from "../pages/restaurant/index";
import Menu from "../pages/menu/index";
import Blog from "../pages/blog/index";
import Post from "../pages/post/index";
import MenuQR from '../pages/admin/menuQR';
import NewProduct from '../pages/admin/newProduct';
import EditProduct from '../pages/admin/editProduct';
import Login from '../pages/admin/login';
import "./App.scss";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/restaurant" component={Restaurant} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/post/:id" component={Post} />
          <UserProvider>
            <Route exact path="/menuQR" component={MenuQR} />
            <Route exact path="/newProduct" component={NewProduct} />
            <Route exact path="/editProduct" component={EditProduct} />
            <Route exact path="/login" component={Login} />
          </UserProvider>
        </Switch>
        <Footer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
