import React, { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./style.scss";
import SpecialitiesSlider from "../../common_components/specialtiesSlider/index";
import ActionButton from "../../common_components/actionButton/index";
import Menu from "./components/menu/index";
import logo from "../../images/Carta_TDP_SMALL.png";
import { LanguageContext } from "../../contexts/Language";
import { MenuPage } from "../../entities/Localization";

function Header() {
  window.scrollTo(0, 0);

  const languageContext = useContext(LanguageContext);

  const localization: MenuPage = Object.assign(
    {},
    languageContext.dictionary.menu
  );

  return (
    <Fragment>
      <Helmet>
        <title>Carta | Taberna del puerto</title>
        <meta
          name="description"
          content="Nuestra carta es una declaración de intenciones hacia nuestra Pasión por el Arroz,
          aunque no solamente encontrarás Arroces entre nuestras especialidades también
          resaltan la comida Mediterránea, además del magnifico producto local."
        />
      </Helmet>
      <div className="container">
        <div className="row  mb-5 mt-5">
          <div className="col">
            <img src={logo} className="logoCarta" alt="..." />
          </div>
        </div>
        <div className="row justify-content-center mb-5 mt-5">
          <div className="col text-center">
            <h1 className="normalText">{localization.title}</h1>
          </div>
        </div>
      </div>
      <SpecialitiesSlider />
      <Menu />
      <div className="container">
        <div className="row  mb-5">
          <div className="rowButtonMenuQR">
            <a
              className="rowButtonTextQR"
              href="https://menupro.beprosystem.com/menu/622ef323997298de8bdfd17f"
            >
              {localization.seeAll}
            </a>
          </div>
        </div>
      </div>
      <ActionButton />
    </Fragment>
  );
}

export default Header;
