import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import trip from "../../images/tripadvisor.png";
import logoimg from "../../images/logofooter.png";

function Footer() {
  const [width, setWidth] = useState<number>(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [isMobile, setIsMobile] = useState(false);

  function book() {
    alert(
      "Teléfono de reservas: +34 628 147 253 \n\nHorario: Lunes - Domingo 11:00-23:00"
    );
  }

  useEffect(() => {
    if (window.innerWidth >= 640) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  const openFacebook = () => {
    window.open("https://www.facebook.com/arroceriatabernadelpuerto", "_blank");
  };

  const openInstagram = () => {
    window.open(
      "https://www.instagram.com/restaurante_taberna_del_puerto/",
      "_blank"
    );
  };

  const openTrip = () => {
    window.open(
      "https://www.tripadvisor.es/Restaurant_Review-g673239-d2077448-Reviews-La_Taberna_del_Puerto-Puerto_Calero_Lanzarote_Canary_Islands.html?m=19905",
      "_blank"
    );
  };

  const openMaps = () => {
    window.open("https://goo.gl/maps/R2n2BrfQ7mRWbajV7", "_blank");
  };

  return (
    <Fragment>
      <div className="footerMain">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-2">
              <div className="">
                <img className="logoFooter" src={logoimg}></img>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="row ">
                <div className="socialImages">
                  <img
                    src={facebook}
                    className="socialImg"
                    alt="..."
                    onClick={() => openFacebook()}
                  />
                  <img
                    src={instagram}
                    className="socialImg"
                    alt="..."
                    onClick={() => openInstagram()}
                  />
                  <img
                    src={trip}
                    className="socialImg"
                    alt="..."
                    onClick={() => openTrip()}
                  />
                </div>
              </div>

              <div className="row justify-content-center mt-2">
                <div className="col-2">
                  <Link to="/">
                    <a className="linkText">Home</a>
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/menu">
                    <p className="linkText">Carta</p>
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/restaurant">
                    <p className="linkText">Local</p>
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/blog">
                    <p className="linkText">Blog</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="row">
                <div className="col">
                  <div className="rowButtonFooter">
                    {isMobile ? (
                      <a
                        className="rowButtonTextFooter"
                        href="tel:+34 628 147 253"
                      >
                        Reservar
                      </a>
                    ) : (
                      <p
                        className="rowButtonTextFooter"
                        onClick={() => {
                          book();
                        }}
                      >
                        Reservar
                      </p>
                    )}
                  </div>
                  <p className="footerText">
                    Horario: Lunes - Domingo 11:00-23:00
                  </p>
                </div>
                <div className="col">
                  <div className="rowButtonFooter" onClick={() => openMaps()}>
                    <p className="rowButtonTextFooter">Cómo llegar</p>
                  </div>
                  <p className="footerText">
                    Paseo Marítimo, Puerto Calero pantalán E, 35570 Yaiza,
                    Lanzarote
                  </p>
                </div>
              </div>
            </div>
            <div className="createContainer">
              <a className="createdText" href="https://www.marketec360.com/">
                Created by: <u>Marketec360</u>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
