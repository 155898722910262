import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Post } from "../../../../entities/Localization";
import "./style.scss";

function PrincipalPost(post: Post) {
  window.scrollTo(0, 0);

  window.addEventListener("resize", handleResize);

  const isMobil: Boolean = window.innerWidth <= 500;

  function handleResize() {
    if (window.innerWidth <= 500) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }
  const [isMobile, setisMobile] = useState(isMobil ? true : false);

  return (
    <Fragment>
      <div className="container">
        <Link
          to={{
            pathname: "/post/0",
          }}
        >
          <div className="headerPrincipalPost">
            {isMobile ? (
              <img
                src={`../posts/${post?.imgsm}`}
                className="backgroundImg"
                alt="..."
              />
            ) : (
              <img
                src={`../posts/${post?.img}`}
                className="backgroundImg"
                alt="..."
              />
            )}
            <div className="infoHeader">
              <p className="infoHeaderTitle">
                <div dangerouslySetInnerHTML={{ __html: post.title }} />
              </p>
              <p className="infoHeaderText">
                <div dangerouslySetInnerHTML={{ __html: post!.subtitle }} />
              </p>
              <div className="infoHeaderButton">
                <Link
                  to={{
                    pathname: "/post/0",
                  }}
                >
                  <a
                    className="infoHeaderButtonText"
                    href="tel:+34 628 147 253"
                  >
                    LEER POST
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </Fragment>
  );
}

export default PrincipalPost;
