import React, { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../contexts/Language";
import { Post } from "../../entities/Localization";
import PrincipalPost from "./components/principalPost/index";
import PostItem from "./components/post/index";
import "./style.scss";



function Blog() {
  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const posts: Post[] = Object.assign({}, languageContext.dictionary.blog.posts);

  const principalPost = Object.values(posts).shift();

  const postItems = Object.values(posts).filter((post) =>
    post !== principalPost).map((post) =>
    <
    PostItem id = {
      post.id
    }
    />
  );

  return (
  <Fragment>
    <Helmet>
      <title>Taberna del puerto | Blog</title>
      <meta name="description" content="¿Conoces la historia de la Paella? Te lo contamos, en pocas líneas
sabrás el origen de este plato icónico dentro de la gastronomía española." />
    </Helmet>
    <PrincipalPost id={principalPost!.id} title={principalPost!.title} subtitle={principalPost!.subtitle}
      text1={principalPost!.text1} text2={principalPost!.text2} text3={principalPost!.text3}
      text4={principalPost!.text4} imageText={principalPost!.imageText} date={principalPost!.date}
      img={principalPost!.img} imgsm={principalPost!.imgsm} firstimg={principalPost!.firstimg}
      secondimg={principalPost!.secondimg} thirdimg={principalPost!.thirdimg} lastimg={principalPost!.lastimg}
      firstimgsm={principalPost!.firstimgsm} />

    <div className="container">
      <div className="recentPost">
        <p className="titleTextBlog">POSTS RECIENTES</p>

        <div className="postList">
          <div className="row row-cols-auto">

            {postItems}

          </div>
        </div>
      </div>


    </div>
  </Fragment>
  );
  }

  export default Blog;