import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { updateProduct } from "../../../services/ProductsServices";
import { Product } from "../../../entities/Localization";

import "./style.scss";
import { useHistory } from "react-router";
import { UserContext } from "../../../contexts/User";

interface FProduct {
  category: string;
  name: string;
  name_en: string;
  name_de: string;
  price: string;
}

function EditProduct(props: { location: { state: Product } }) {
  window.scrollTo(0, 0);

  let history = useHistory();

  const product: Product = props.location.state || {};

  const [isShowingSuccessAlert, setShowingSuccessAlert] = useState(false);
  const [isShowingDangerAlert, setShowingDangerAlert] = useState(false);

  const userContext = useContext(UserContext);
  const token: string = userContext.token;

  const goBack = () => {
    history.push({
      pathname: "/menuQR",
    });
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const closeDangerAlert = () => {
    (async () => {
      await delay(2000);
      setShowingDangerAlert(false);
    })();
  };

  const closeSuccessAlert = () => {
    (async () => {
      await delay(2000);
      setShowingSuccessAlert(false);
    })();
  };

  const { register, handleSubmit } = useForm<FProduct>();

  const onSubmit = async (data: FProduct) => {
    const dataToSend = new Product();
    dataToSend._id = product._id;
    dataToSend.category = Number(data.category);
    dataToSend.name = data.name;
    dataToSend.name_en = data.name_en;
    dataToSend.name_de = data.name_de;
    dataToSend.price = data.price;
    console.log(dataToSend);

    try {
      const res = await updateProduct(dataToSend, token);
      console.log(res);
      setShowingSuccessAlert(true);
      closeSuccessAlert();
      goBack();
    } catch (error) {
      setShowingDangerAlert(true);
      closeDangerAlert();
      userContext.setToken("");
      goBack();
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                NombreES
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                {...register("name")}
                defaultValue={product.name}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                NombreEN
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                {...register("name_en")}
                defaultValue={product.name_en}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                NombreDE
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                {...register("name_de")}
                defaultValue={product.name_de}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Precio
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                {...register("price")}
                defaultValue={product.price}
              />
            </div>
            <div className="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("category")}
                defaultValue={String(product.category)}
              >
                <option value="0" selected>
                  PARA PICAR
                </option>
                <option value="1">ENTRANTES CALIENTES</option>
                <option value="2">SUGERENCIAS</option>
                <option value="3">ENSALADAS</option>
                <option value="4">ARROCES</option>
                <option value="5">PESCADOS AL GRILL</option>
                <option value="6">PESCADOS VARIADOS</option>
                <option value="7">PASTAS</option>
                <option value="8">CARNES</option>
                <option value="9">MENÚ PARA NIÑOS</option>
                <option value="10">POSTRES</option>
                <option value="11">CAFÉS</option>
                <option value="12">VINOS DE LA CASA</option>
                <option value="13">D.O LANZAROTE - VINO BLANCO</option>
                <option value="14">D.O LANZAROTE - VINO TINTO</option>
                <option value="15">D.O LANZAROTE (SEMI-DULCE)</option>
                <option value="16">IMPORTADOS (BLANCO)</option>
                <option value="17">IMPORTADOS (VINO ROSADO)</option>
                <option value="18">D.O RIBERA DEL DUERO</option>
                <option value="19">D.O RIBERA DEL DUERO - RESERVAS</option>
                <option value="20">D.O RIOJA</option>
                <option value="21">D.O RIOJA - RESERVAS</option>
                <option value="22">CHAMPAGNE CAVAS</option>
                <option value="23">SANGRÍA</option>
                <option value="24">AGUA</option>
                <option value="25">REFRESCOS</option>
                <option value="26">BEBIDAS CON ALCOHOL</option>
                <option value="27">ZUMOS</option>
              </select>
            </div>

            <button type="submit" className="btn btn-secondary">
              Editar
            </button>
          </form>
        </div>
      </div>
      <div
        className={`alert alert-success ${
          isShowingSuccessAlert ? "alert-shown" : "alert-hidden"
        }`}
      >
        Producto modificado correctamente
      </div>
      <div
        className={`alert alert-danger ${
          isShowingDangerAlert ? "alert-shown" : "alert-hidden"
        }`}
      >
        No se a podido modificar el producto
      </div>
    </Fragment>
  );
}

export default EditProduct;
