import React, { Fragment } from "react";
import carouselimg1 from "../../images/ES_01.jpg";
import carouselimg2 from "../../images/ES_02.jpg";
import carouselimg3 from "../../images/ES_03.jpg";
import carouselimg4 from "../../images/ES_04.jpg";
import carouselimg5 from "../../images/ES_05.jpg";
import carouselimg6 from "../../images/ES_06.jpg";
import carouselimg7 from "../../images/ES_07.jpg";
import carouselimg8 from "../../images/ES_08.jpg";
import "./style.scss";

function SpecialitiesSlider() {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <div className="container">
        <div className="row justify-content-center mb-5 mt-5 page-header">
          <div className="col-md-10">
            <div
              id="carouselExampleCaptions"
              className="carousel slide spcialities-carousel"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={carouselimg1}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">Arroz Negro</h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg2}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">Arroz A Banda</h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg3}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">Fidegua Negra</h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg4}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">
                      Arroz De Secreto Ibérico
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg5}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">
                      Arroz Caldoso De Bogavante
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg6}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">
                      Garbanzos Con Carabineros
                    </h3>
                  </div>
                </div>

                <div className="carousel-item">
                  <img
                    src={carouselimg7}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">
                      Arroz Seco Con Bogavante
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={carouselimg8}
                    className="carousel-item-img"
                    alt="..."
                  />
                  <div className="row justify-content-center mt-5">
                    <h3 className="specialitiesRiceName">Arroz A Tu Gusto</h3>
                  </div>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleCaptions"
                role="button"
                data-slide="prev"
              >
                <span className="prev-button" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleCaptions"
                role="button"
                data-slide="next"
              >
                <span className="next-button" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SpecialitiesSlider;
