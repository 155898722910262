import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { LanguageContext } from "../../../../contexts/Language";
import { Quikactions } from "../../../../entities/Localization";

function QuikActions() {
  window.scrollTo(0, 0);

  const languageContext = useContext(LanguageContext);

  const localization: Quikactions = Object.assign(
    {},
    languageContext.dictionary.home.quikactions
  );

  return (
    <Fragment>
      <div className="container">
        <div className="containerQuik">
          <div className="row justify-content-center mt-5 mb-3">
            <h1 className="homeTitleText">{localization.title}</h1>
          </div>
          <div className="row justify-content-center mb-3">
            <h2 className="titleText">{localization.subtitle}</h2>
          </div>
          <div className="row justify-content-center mb-3">
            <p
              className="homeText"
              dangerouslySetInnerHTML={{ __html: localization.text }}
            />
          </div>
          <div className="row">
            <div className="col">
              <div className="rowButton">
                <Link to="/menu">
                  <a className="rowButtonText">{localization.action1}</a>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="rowButton">
                <Link to="/restaurant">
                  <a className="rowButtonText">{localization.action2}</a>
                </Link>
              </div>
            </div>
          </div>
            
        </div>
      </div>
    </Fragment>
  );
}

export default QuikActions;
