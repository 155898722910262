import React from "react";
import "./style.scss";

function ActionButton() {
  return (
    <a href="tel:+34 628 147 253" className="float" target="_blank" rel="noreferrer">
      <i className="fa fa-phone my-float"></i>
    </a>
  );
}

export default ActionButton;
