import axios from "axios";
import {
  Category,
  LoginDataOut,
  Product,
  User,
} from "../entities/Localization";

export const getProducts = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  return await axios.get<Product[]>(
    "https://api.tabernadelpuertolanzarote.com/products",
    config
  );
};

export const getCategories = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  return await axios.get<Category[]>(
    "https://api.tabernadelpuertolanzarote.com/categorys",
    config
  );
};

export const sendProduct = async (product: Product, token: string) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
  };
  return await axios.post<Product[]>(
    "https://api.tabernadelpuertolanzarote.com/products",
    product,
    config
  );
};

export const deleteProduct = async (product: Product, token: string) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
  };
  return await axios.delete<Product[]>(
    "https://api.tabernadelpuertolanzarote.com/products/" + product._id,
    config
  );
};

export const updateProduct = async (product: Product, token: string) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
  };
  return await axios.put<Product[]>(
    "https://api.tabernadelpuertolanzarote.com/products/" + product._id,
    product,
    config
  );
};

export const login = async (user: User) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  return await axios.post<LoginDataOut>(
    "https://api.tabernadelpuertolanzarote.com/login",
    user,
    config
  );
};
