import React, { Fragment, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../contexts/Language";
import { Identifiable, Post } from "../../entities/Localization";
import Header from "./components/header/index";
import "./style.scss";
import { useLocation, useParams, RouteComponentProps } from "react-router";

function PostPage({ match }: RouteComponentProps<Identifiable>) {
  window.scrollTo(0, 0);
  window.addEventListener("resize", handleResize);

  const isMobil: Boolean = window.innerWidth <= 500;

  function handleResize() {
    if (window.innerWidth <= 500) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }

  const [isMobile, setisMobile] = useState(isMobil ? true : false);

  const languageContext = useContext(LanguageContext);

  const posts: Post[] = Object.assign(
    {},
    languageContext.dictionary.blog.posts
  );

  const post = Object.values(posts)
    .filter((post) => post.id == match.params.id)
    .shift();

  return (
    <Fragment>
      <Helmet>
        <title>Taberna del puerto | Blog</title>
        <meta
          name="description"
          content="¿Conoces la historia de la Paella? Te lo contamos, en pocas líneas
sabrás el origen de este plato icónico dentro de la gastronomía española."
        />
      </Helmet>
      <Header
        id={post!.id}
        img={post!.img}
        imgsm={post!.imgsm}
        title={post!.title}
        subtitle={post!.subtitle}
        text1={post!.text1}
        firstimg={post!.firstimg}
        text2={post!.text2}
        secondimg={post!.secondimg}
        thirdimg={post!.thirdimg}
        text3={post!.text3}
        lastimg={post!.lastimg}
        text4={post!.text4}
        imageText={post!.imageText}
        date={post!.date}
        firstimgsm={post!.firstimgsm}
      />

      <div className="container">
        <div className="postContent">
          <div className="row justify-content-center">
            <div className="col text-center">
              <p className="titleTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.subtitle }} />
              </p>

              <p className="normalTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.text1 }} />
              </p>
            </div>
          </div>
          <div>
            <div className="centerImg img1">
              {isMobile ? (
                <img src={"/posts/" + post?.firstimgsm} className="centerImg" />
              ) : (
                <img src={"/posts/" + post?.firstimg} className="centerImg" />
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col text-center" style={{ margin: "auto" }}>
              <p className="normalTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.text2 }} />
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <img src={"/posts/" + post?.secondimg} className="centerImg" />
            </div>
            <div className="col-md-6 text-center">
              <img src={"/posts/" + post?.thirdimg} className="centerImg"></img>
            </div>
          </div>

          <p className="imageTextPost">
            <div dangerouslySetInnerHTML={{ __html: post!.imageText }} />
          </p>

          <div className="row justify-content-center">
            <div className="col text-center" style={{ margin: "auto" }}>
              <p className="normalTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.text3 }} />
              </p>
            </div>
          </div>
          <div>
            {post?.lastimg != "" && (
              <img src={"/posts/" + post?.lastimg} className="centerImg"></img>
            )}
          </div>

          <div className="row justify-content-center">
            <div className="col text-center" style={{ margin: "auto" }}>
              <p className="normalTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.text4 }} />
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col text-center" style={{ marginBottom: "50px" }}>
              <p className="rightTextPost">
                <div dangerouslySetInnerHTML={{ __html: post!.date }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PostPage;
