import React, { Fragment, useContext } from "react";
import { LanguageContext } from "../../../../contexts/Language";
import { MenuSection } from "../../../../entities/Localization";
import ent1 from "../../../../images/EN_CARTA_01_TDP.jpg";
import ent2 from "../../../../images/EN_CARTA_02_TDP.jpg";
import ent3 from "../../../../images/EN_CARTA_03_TDP.jpg";
import ent4 from "../../../../images/EN_CARTA_04_TDP.jpg";
import arr1 from "../../../../images/ARR_CARTA_01_TDP.png";
import arr2 from "../../../../images/ARR_CARTA_02_TDP.png";
import arr3 from "../../../../images/ARR_CARTA_03_TDP.png";
import arr4 from "../../../../images/ARR_CARTA_04_TDP.png";
import arr5 from "../../../../images/ARR_CARTA_05_TDP.png";
import arr6 from "../../../../images/ARR_CARTA_06_TDP.png";
import vin1 from "../../../../images/BermejoBlanco.jpg";
import vin2 from "../../../../images/Vulcano_semidulce.jpg";
import vin3 from "../../../../images/terrasgauda.jpg";
import vin4 from "../../../../images/EmilioMoro.jpg";
import vin5 from "../../../../images/Grifo_tinto_ariana.jpg";
import vin6 from "../../../../images/VegaSicilia_Alion.jpg";

import "./style.scss";

function Menu() {
  const languageContext = useContext(LanguageContext);

  const localization: MenuSection = Object.assign(
    {},
    languageContext.dictionary.menu.menuSection
  );

  return (
    <Fragment>
      <div className="container">
        <div className="Delicious_area">
          <div className="tablist_menu">
            <div className="row">
              <div className="col-xl-12">
                <ul
                  className="nav justify-content-center flex-column flex-sm-row"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-caribean-tab"
                      data-toggle="pill"
                      href="#pills-caribean"
                      role="tab"
                      aria-controls="pills-caribean"
                      aria-selected="true"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.section1.title}</h4>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-special-tab"
                      data-toggle="pill"
                      href="#pills-special"
                      role="tab"
                      aria-controls="pills-special"
                      aria-selected="false"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.section2.title}</h4>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-classic-tab"
                      data-toggle="pill"
                      href="#pills-classic"
                      role="tab"
                      aria-controls="pills-classic"
                      aria-selected="false"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.section3.title}</h4>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pb-3">
            <div className="col-md-10">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-caribean"
                  role="tabpanel"
                  aria-labelledby="pills-caribean-tab"
                >
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={ent1} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section1.items[0].title}</h3>
                          <p>{localization.section1.items[0].subtitle}</p>
                          <span>{localization.section1.items[0].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={ent2} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section1.items[1].title}</h3>
                          <p>{localization.section1.items[1].subtitle}</p>
                          <span>{localization.section1.items[1].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={ent3} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section1.items[2].title}</h3>
                          <p>{localization.section1.items[2].subtitle}</p>
                          <span>{localization.section1.items[2].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={ent4} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section1.items[3].title}</h3>
                          <p>{localization.section1.items[3].subtitle}</p>
                          <span>{localization.section1.items[3].price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-special"
                  role="tabpanel"
                  aria-labelledby="pills-special-tab"
                >
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr1} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[0].title}</h3>
                          <p>{localization.section2.items[0].subtitle}</p>
                          <span>{localization.section2.items[0].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr3} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[1].title}</h3>
                          <p>{localization.section2.items[1].subtitle}</p>
                          <span>{localization.section2.items[1].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr4} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[2].title}</h3>
                          <p>{localization.section2.items[2].subtitle}</p>
                          <span>{localization.section2.items[2].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr5} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[3].title}</h3>
                          <p>{localization.section2.items[3].subtitle}</p>
                          <span>{localization.section2.items[3].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr2} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[4].title}</h3>
                          <p>{localization.section2.items[4].subtitle}</p>
                          <span>{localization.section2.items[4].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={arr6} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section2.items[5].title}</h3>
                          <p>{localization.section2.items[5].subtitle}</p>
                          <span>{localization.section2.items[5].price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-classic"
                  role="tabpanel"
                  aria-labelledby="pills-classic-tab"
                >
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin1} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[0].title}</h3>
                          <p>{localization.section3.items[0].subtitle}</p>
                          <span>{localization.section3.items[0].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin2} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[1].title}</h3>
                          <p>{localization.section3.items[1].subtitle}</p>
                          <span>{localization.section3.items[1].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin3} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[2].title}</h3>
                          <p>{localization.section3.items[2].subtitle}</p>
                          <span>{localization.section3.items[2].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-lg-6">
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin4} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[3].title}</h3>
                          <p>{localization.section3.items[3].subtitle}</p>
                          <span>{localization.section3.items[3].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin5} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[4].title}</h3>
                          <p>{localization.section3.items[4].subtitle}</p>
                          <span>{localization.section3.items[4].price}</span>
                        </div>
                      </div>
                      <div className="single_delicious d-flex align-items-center">
                        <div className="thumb">
                          <img src={vin6} alt="" className="productImg" />
                        </div>
                        <div className="info">
                          <h3>{localization.section3.items[5].title}</h3>
                          <p>{localization.section3.items[5].subtitle}</p>
                          <span>{localization.section3.items[5].price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Menu;
