// To parse this data:
//
//   import { Convert, Welcome } from "./file";
//
//   const welcome = Convert.toWelcome(json);

export interface Localization {
  home: Home;
  local: Local;
  menu: MenuPage;
  footer: Footer;
  menuQR: MenuQRData;
  blog: Blog;
}

export interface Footer {
  links: Links;
  book: Book;
  go: Book;
}

export interface Book {
  button: string;
  text: string;
}

export interface Links {
  link1: string;
  link2: string;
  link3: string;
}

export interface Home {
  quikactions: Quikactions;
  specialities: Specialities;
  rrss: Rrss;
}

export interface Quikactions {
  title: string;
  text: string;
  subtitle: string;
  action1: string;
  action2: string;
}

export interface Rrss {
  title: string;
}

export interface Specialities {
  title: string;
  subtitle: string;
}

export interface Local {
  title: string;
  subtitle: string;
  section1: Specialities;
  section2: Specialities;
}

export interface MenuPage {
  title: string;
  menuSection: MenuSection;
  seeAll: string;
}

export interface MenuSection {
  section1: Section;
  section2: Section;
  section3: Section;
}

export interface Section {
  title: string;
  items: Item[];
}

export interface Item {
  title: string;
  subtitle: string;
  price: string;
}

export interface MenuQRData {
  tab1: string;
  tab2: string;
  tax: string;
  inf1: string;
  inf1see: string;
  info1url: string;
  inf2: string;
  menu: Menu[];
  drinks: Drinks[];
}

export interface Menu {
  section: string;
  items: MenuItem[];
}

export interface MenuItem {
  name: string;
  price: string;
}

export interface Drinks {
  section: string;
  items: DrinksItem[];
}

export interface DrinksItem {
  name: string;
  price: string;
}

export interface Blog {
  posts: Post[];
}

export interface Post {
  id: string;
  img: string;
  imgsm: string;
  title: string;
  subtitle: string;
  text1: string;
  firstimg: string;
  firstimgsm: string;
  text2: string;
  secondimg: string;
  thirdimg: string;
  text3: string;
  lastimg: string;
  text4: string;
  imageText: string;
  date: string;
}

export interface Identifiable {
  id: string;
}

export class User {
  user!: string;
  password!: string;
}

export class Product {
  category!: Number;
  name!: string;
  name_en!: string;
  name_de!: string;
  price!: string;
  _id!: string;
}

export class Category {
  id!: number;
  name!: string;
  name_en!: string;
  name_de!: string;
}

export interface LoginDataOut {
  token: string;
}
