import React, { Fragment, useContext, useReducer, useState } from "react";
import { login } from "../../../services/ProductsServices";
import { useForm } from "react-hook-form";
import { updateProduct } from "../../../services/ProductsServices";
import { Product, User } from "../../../entities/Localization";

import "./style.scss";
import { useHistory } from "react-router";
import { UserContext } from "../../../contexts/User";

function Login() {
  window.scrollTo(0, 0);

  const userContext = useContext(UserContext);

  let history = useHistory();

  const { register, handleSubmit } = useForm<User>();

  const onSubmit = async (data: User) => {
    try {
      const dataToSend = new User();
      dataToSend.user = data.user;
      dataToSend.password = data.password;
      console.log(dataToSend);
      const res = await login(dataToSend);
      userContext.setToken(res.data.token);
      history.push({
        pathname: "/menuQR",
        state: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Usuario
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            {...register("user")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Contraseña
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            {...register("password")}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </Fragment>
  );
}

export default Login;
