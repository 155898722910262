import React, { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../contexts/Language";
import { Local } from "../../entities/Localization";
import ActionButton from "../../common_components/actionButton/index";
import Slider from "./components/slider/index";
import Map from "../../common_components/map/index";
import Header from "./components/header/index";
import img1 from "../../images/TZ_LOCAL_01_TDP.jpg";
import "./style.scss";

function Restaurant() {
  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const localization: Local = Object.assign(
    {},
    languageContext.dictionary.local
  );
  return (
    <Fragment>
      <Helmet>
        <title>Taberna del puerto | Puerto Calero</title>
        <meta
          name="description"
          content="La Taberna del Puerto, se encuentra en Puerto Calero un puerto deportivo magnifico
          y con un ambiente inmejorable. Desde nuestra terraza podrás disfrutar de un clima
          inigualable y unos atardeceres memorables."
        />
      </Helmet>
      <Slider />
      <Fragment>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col text-center" style={{ marginBottom: "20px" }}>
              <h1 className="titleTextRest">{localization.title}</h1>

              <h2 className="normalText">{localization.subtitle}</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 text-center" style={{ margin: "auto" }}>
              <h3 className="titleTextRest">{localization.section1.title}</h3>

              <h3 className="normalText">{localization.section1.subtitle}</h3>
            </div>
            <div className="col-md-6 order-first order-md-last text-center">
              <img src={img1} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3492.36836656949!2d-13.706224884944067!3d28.917112177894822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc46256a410bae45%3A0xa2a236cb83e76717!2sLa%20Taberna%20del%20Puerto!5e0!3m2!1ses!2ses!4v1598268070765!5m2!1ses!2ses"
                width="100%"
                height="100%"
                frameBorder="0"
                aria-hidden="false"
              ></iframe>
            </div>
            <div className="col-md-6 text-center" style={{ margin: "auto" }}>
              <h3 className="normalText" style={{ marginTop: "20px" }}>
                {localization.section2.subtitle}
              </h3>
            </div>
          </div>
        </div>
      </Fragment>
      <ActionButton />
    </Fragment>
  );
}

export default Restaurant;
