import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import logo from "../../images/logomain.png";

function NavBar() {
  const [width, setWidth] = useState<number>(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [isMobile, setIsMobile] = useState(false);

  function book() {
    alert(
      "Teléfono de reservas: +34 628 147 253 \n\nHorario: Lunes - Domingo 11:00-23:00"
    );
  }

  useEffect(() => {
    if (window.innerWidth >= 640) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top menuNav">
        <div className="container">
          <Link to="/">
            <img src={logo} className="logoImg" alt="..." />
          </Link>
          {/* <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Dropdown
          </a> */}

          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="nav-link">Home</a>
            <a className="nav-link">Home</a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav menuItems">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/">
                  <p className="nav-link menuNavText">Home</p>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/restaurant">
                  <p className="nav-link menuNavText">Local</p>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/menu">
                  <p className="nav-link menuNavText">Carta</p>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/blog">
                  <p className="nav-link menuNavText">Blog</p>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                {isMobile ? (
                  <a
                    className="nav-link menuNavText"
                    href="tel:+34 628 147 253"
                  >
                    Reservar
                  </a>
                ) : (
                  <p
                    className="nav-link menuNavText"
                    onClick={() => {
                      book();
                    }}
                  >
                    Reservar
                  </p>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}

export default NavBar;
