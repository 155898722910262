import React, { useState, createContext, useContext, useEffect } from "react";

// create the language context with default selected language
export const UserContext = createContext({
  token: localStorage.getItem("token") || "",
  setToken: (token: string) => {},
});

// it provides the language context to app
export function UserProvider(props: any) {
  const userContext = useContext(UserContext);
  const [token, setToken] = useState(userContext.token);

  const provider = {
    token,
    setToken: (token: string) => {
      setToken(token);
    },
  };

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  return (
    <UserContext.Provider value={provider}>
      {props.children}
    </UserContext.Provider>
  );
}

// // get text according to id & current language
// export function Text(props: string) {
//   const languageContext = useContext(LanguageContext);

//   return languageContext.dictionary[props];
// }
