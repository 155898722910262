import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./style.scss";
import { LanguageContext } from "../../../contexts/Language";
import {
  Category,
  LoginDataOut,
  MenuQRData,
  Product,
} from "../../../entities/Localization";
import {
  getProducts,
  deleteProduct,
  getCategories,
} from "../../../services/ProductsServices";
import espanaimg from "../../../images/espana.png";
import alemaniaimg from "../../../images/alemania.png";
import granbretañaimg from "../../../images/gran-bretaña.png";
import logoimg from "../../images/logo.jpg";
import arrocesimg from "../../../images/arroces.png";
import calientesimg from "../../../images/calientes.png";
import carnesimg from "../../../images/carnes.png";
import ensaladasimg from "../../../images/ensaladas.png";
import entrantesimg from "../../../images/entrantes.png";
import menuninosimg from "../../../images/menu-ninos.png";
import pastasimg from "../../../images/pastas.png";
import pescadosimg from "../../../images/pescados.png";
import postresimg from "../../../images/postres.png";
import sugerenciasimg from "../../../images/sugerencias.png";
import cafeimg from "../../../images/cafes.png";
import pescadogrillimg from "../../../images/pescado-grill.png";
import comidasimg from "../../../images/comidas.png";
import bebidasimg from "../../../images/bebidas.png";
import aguaimg from "../../../images/agua.png";
import alcohoimg from "../../../images/alcoho.png";
import champagneimg from "../../../images/champagne.png";
import noalcohoimg from "../../../images/no-alcoho.png";
import vinoimg from "../../../images/vino.png";
import zumoimg from "../../../images/zumo.png";
import tree from "../../images/tree.png";
import { UserContext } from "../../../contexts/User";

function MenuQR() {
  window.scrollTo(0, 0);

  const [products, setProducts] = useState<Product[]>([]);
  const [categorys, setCategorys] = useState<Category[]>([]);
  const [deletedProduct, setDeleteProduct] = useState<Product>();
  const [isShowingDangerAlert, setShowingDangerAlert] = useState(false);
  const [isShowingSuccessAlert, setShowingSuccessAlert] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const loadProducts = async () => {
    const res = await getProducts();
    setProducts(res.data);
  };

  const loadCategories = async () => {
    const res = await getCategories();

    let sugerencias = res.data.filter((category) => category.id == 2)[0];

    res.data.forEach((item, index) => {
      if (item.id == 2) res.data.splice(index, 1);
    });

    res.data.unshift(sugerencias);

    setCategorys(res.data);
  };;

  useEffect(() => {
    loadProducts();
    loadCategories();
    return () => {};
  }, []);

  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);

  const token: string = userContext.token;

  const hasToken = () => {
    return token != "";
  };

  let history = useHistory();

  const handleENLanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage("en");
    console.log(languageContext);
  };

  const handleESLanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage("es");
    console.log(languageContext);
  };

  const handleDELanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage("de");
    console.log(languageContext);
  };

  const menu: MenuQRData = Object.assign({}, languageContext.dictionary.menuQR);

  let images = [
    sugerenciasimg,
    entrantesimg,
    calientesimg,
    ensaladasimg,
    arrocesimg,
    pescadogrillimg,
    pescadosimg,
    pastasimg,
    carnesimg,
    menuninosimg,
    postresimg,
    cafeimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    champagneimg,
    champagneimg,
    aguaimg,
    noalcohoimg,
    alcohoimg,
    zumoimg,
  ];

  const createTable = () => {
    let table: JSX.Element[] = [];
    categorys
      .filter((category) => category.id < 12)
      .map((category, index) => {
        table.push(
          <thead className="thead">
            <tr>
              <th scope="col" colSpan={2}>
                <img className="icon-image" src={images[index]}></img>
                {(() => {
                  switch (languageContext.language) {
                    case "es":
                      return category.name;
                    case "en":
                      return category.name_en;
                    case "de":
                      return category.name_de;
                  }
                })()}
              </th>
            </tr>
          </thead>
        );
        products
          .filter((product) => product.category == category.id)
          .map((product, index) => {
            table.push(
              <tbody className="tbody">
                <tr>
                  <td>
                    {(() => {
                      switch (languageContext.language) {
                        case "es":
                          return product.name;
                        case "en":
                          return product.name_en;
                        case "de":
                          return product.name_de;
                      }
                    })()}
                  </td>
                  <td>{product.price}</td>

                  {hasToken() && (
                    <Fragment>
                      <td>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => editProduct(product)}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => removeProduct(product)}
                        >
                          {" "}
                          <i className="bi bi-trash"></i>
                        </button>
                      </td>{" "}
                    </Fragment>
                  )}
                </tr>
              </tbody>
            );
          });
      });

    return table;
  };

  const createDrinksTable = () => {
    let table: JSX.Element[] = [];

    categorys
      .filter((category) => category.id >= 12)
      .map((category, index) => {
        table.push(
          <thead className="thead">
            <tr>
              <th scope="col" colSpan={2}>
                <img className="icon-image" src={images[category.id]}></img>
                {(() => {
                  switch (languageContext.language) {
                    case "es":
                      return category.name;
                    case "en":
                      return category.name_en;
                    case "de":
                      return category.name_de;
                  }
                })()}
              </th>
            </tr>
          </thead>
        );
        products
          .filter((product) => product.category == category.id)
          .map((product, index) => {
            table.push(
              <tbody className="tbody">
                <tr>
                  <td>
                    {(() => {
                      switch (languageContext.language) {
                        case "es":
                          return product.name;
                        case "en":
                          return product.name_en;
                        case "de":
                          return product.name_de;
                      }
                    })()}
                  </td>
                  <td>{product.price}</td>
                  {hasToken() && (
                    <Fragment>
                      <td>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => editProduct(product)}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => removeProduct(product)}
                        >
                          {" "}
                          <i className="bi bi-trash"></i>
                        </button>
                      </td>{" "}
                    </Fragment>
                  )}
                </tr>
              </tbody>
            );
          });
      });

    return table;
  };

  const logOut = () => {
    userContext.setToken("");
    window.location.reload();
  };

  const removeProduct = (product: Product) => {
    setShowingModal(true);
    setDeleteProduct(product);
  };

  const confirmRemoveProduct = async () => {
    setShowingModal(false);
    try {
      const res = await deleteProduct(deletedProduct!, token);
      setShowingSuccessAlert(true);
      closeSuccessAlert();
      loadProducts();
    } catch (error) {
      setShowingDangerAlert(true);
      closeDangerAlert();
      userContext.setToken("");
      window.location.reload();
    }
  };

  const editProduct = (product: Product) => {
    history.push({
      pathname: "/editProduct",
      state: product,
    });
  };

  const addProduct = () => {
    history.push({
      pathname: "/newProduct",
      state: token,
    });
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const closeSuccessAlert = () => {
    (async () => {
      await delay(2000);
      setShowingSuccessAlert(false);
    })();
  };

  const closeDangerAlert = () => {
    (async () => {
      await delay(2000);
      setShowingDangerAlert(false);
    })();
  };

  return (
    <Fragment>
      <div className="tabs">
        <div className="language-row">
          <a onClick={handleESLanguageChange}>
            <img className="icon-image" src={espanaimg}></img>
          </a>
          <a onClick={handleDELanguageChange}>
            <img className="icon-image" src={alemaniaimg}></img>
          </a>
          <a onClick={handleENLanguageChange}>
            <img className="icon-image" src={granbretañaimg}></img>
          </a>
        </div>

        <div className="logo"></div>

        {hasToken() && (
          <div className="addButton">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => addProduct()}
            >
              <i className="bi bi-plus-square"></i> Añadir producto
            </button>
            <button
              type="button"
              className="btn btn-light logoutButton"
              onClick={() => logOut()}
            >
              <i className="bi bi-x-circle"></i> Cerrar sesión
            </button>
          </div>
        )}

        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <img className="icon-image" src={comidasimg}></img>
              {menu.tab1}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              <img className="icon-image" src={bebidasimg}></img>
              {menu.tab2}
            </a>
          </li>
        </ul>
      </div>
      <div className="table-responsive table-borderless menuQR">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <table className="table">{createTable()}</table>
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <table className="table">{createDrinksTable()}</table>
          </div>
        </div>
      </div>
      <div className="footer">
        <p>{menu.tax}</p>
        <p>
          {menu.inf1}{" "}
          <a href={menu.info1url} target="_blank">
            {menu.inf1see}
          </a>
        </p>

        <p>{menu.inf2}</p>
      </div>
      <div
        className={`alert alert-danger ${
          isShowingDangerAlert ? "alert-shown" : "alert-hidden"
        }`}
      >
        No se ha podido eliminar el producto
      </div>
      <div
        className={`alert alert-success ${
          isShowingSuccessAlert ? "alert-shown" : "alert-hidden"
        }`}
      >
        Producto eliminado correctamente
      </div>

      <div
        className={`${isShowingModal ? "modal-shown" : "modal-hidden"}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Estas seguro que quiere eliminar este producto
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowingModal(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => confirmRemoveProduct()}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MenuQR;
