import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../contexts/Language";
import { Identifiable, Post } from "../../../../entities/Localization";
import "./style.scss";


function PostItem(id: Identifiable) {

    window.scrollTo(0, 0);
    const languageContext = useContext(LanguageContext);

    const posts: Post[] = Object.assign({}, languageContext.dictionary.blog.posts);

    const post = Object.values(posts).filter((post) =>
        post.id == id.id
    ).shift();

return (
  <Fragment>
    <div className="col-6 col-md-2 post">
      <Link
        to={{
          pathname: "/post/" + id.id,
        }}
      >
        <div className="card">
          <img
            src={"./posts/" + post?.imgsm}
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <p className="card-title">{post?.title}</p>
            <p className="card-subtitle">{post?.date}</p>
          </div>
        </div>
      </Link>
    </div>
  </Fragment>
);
}

export default PostItem;