import React, { Fragment } from "react";
import facebook from "../../../../images/facebook.png";
import instagram from "../../../../images/instagram.png";
import instagramImg1 from "../../../../images/RRSS_IG_01_TDP.jpg";
import instagramImg2 from "../../../../images/RRSS_IG_02_TDP.jpg";
import instagramImg3 from "../../../../images/RRSS_IG_03_TDP.jpg";
import instagramImg4 from "../../../../images/RRSS_IG_04_TDP.jpg";
import instagramImg5 from "../../../../images/RRSS_IG_05_TDP.jpg";
import instagramImg6 from "../../../../images/RRSS_IG_06_TDP.jpg";
import instagramImg7 from "../../../../images/RRSS_IG_07_TDP.jpg";
import instagramImg8 from "../../../../images/RRSS_IG_08_TDP.jpg";
import instagramImg9 from "../../../../images/RRSS_IG_09_TDP.jpg";
import fcImg1 from "../../../../images/RRSS_FC_01_TDP.jpg";
import fcImg2 from "../../../../images/RRSS_FC_02_TDP.jpg";
import fcImg3 from "../../../../images/RRSS_FC_03_TDP.jpg";
import fcImg4 from "../../../../images/RRSS_FC_04_TDP.jpg";
import fcImg5 from "../../../../images/RRSS_FC_05_TDP.jpg";

import "./style.scss";

function RRSS() {
  window.scrollTo(0, 0);

  const openFacebook = () => {
    window.open("https://www.facebook.com/arroceriatabernadelpuerto", "_blank");
  };

  const openInstagram = () => {
    window.open(
      "https://www.instagram.com/restaurante_taberna_del_puerto/",
      "_blank"
    );
  };
  return (
    <Fragment>
      <div className="container">
        <div className="row rssRow justify-content-center ">
          <div className="col-md-6 mt-4">
            <div className="rowTitle">
              <img
                src={instagram}
                alt=""
                className="rowLogo"
                onClick={() => openInstagram()}
              />
            </div>
            <div className="row">
              <div className="col nopadding">
                <img
                  src={instagramImg1}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg2}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg3}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col nopadding">
                <img
                  src={instagramImg4}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg5}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg6}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col nopadding">
                <img
                  src={instagramImg7}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg8}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col nopadding">
                <img
                  src={instagramImg9}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="rowTitle">
              <img
                src={facebook}
                alt=""
                className="rowLogo"
                height="40px"
                onClick={() => openFacebook()}
              />
            </div>
            <div className="row d-block">
              <div className="col-8 float-left nopadding">
                <img
                  src={fcImg1}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="col-4 float-left nopadding">
                <img
                  src={fcImg3}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="col-4 float-left nopadding">
                <img
                  src={fcImg4}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
            </div>
            <div className="row d-block">
              <div className="col-8 float-left nopadding">
                <img
                  src={fcImg2}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="col-4 float-left nopadding">
                <img
                  src={fcImg5}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RRSS;
