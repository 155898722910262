import React, { Fragment, useState } from "react";
import { Post } from "../../../../entities/Localization";
import "./style.scss";

function Slider(post: Post) {
  window.scrollTo(0, 0);
  window.addEventListener('resize', handleResize)

  const isMobil: Boolean = window.innerWidth <= 500;

  function handleResize() {

    if (window.innerWidth <= 500) {
      setisMobile(true)
    } else {
      setisMobile(false)
    }
  }
  const [isMobile, setisMobile] = useState(isMobil ? true : false);

  return (
    <Fragment>
      <div className="container">
        <div className="headerBlog">
          {isMobile ? (
            <img
              src={"/posts/" + post?.imgsm}
              className="backgroundImg"
              alt="..."
            />
          ) : (
            <img
              src={"/posts/" + post?.img}
              className="backgroundImg"
              alt="..."
            />
          )}
        </div>
      </div>
    </Fragment>
  );
  }

  export default Slider;
