import React, { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/Language";
import { Specialities } from "../../entities/Localization";
import ActionButton from "../../common_components/actionButton/index";
import Header from "./components/header/index";
import Slider from "./components/slider/index";
import QuikActions from "./components/quikactions/index";
import SpecialitiesSlider from "../../common_components/specialtiesSlider/index";
import RRSS from "./components/rrss/index";
import "./style.scss";

function Home() {
  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const localization: Specialities = Object.assign(
    {},
    languageContext.dictionary.home.specialities
  );
  return (
    <Fragment>
      <Helmet>
        <title>Taberna del Puerto | Los Mejores Arroces de Lanzarote</title>
        <meta
          name="description"
          content="En el Restaurante La Taberna del Puerto disfrutarás de los mejores Arroces de
          Lanzarote y todo nuestro secreto es gracias a un sencillo pero indispensable detalle,
          nuestra Pasión por el Arroz. Nos encontramos en Puerto Calero, un puerto deportivo
          magnifico y con unos atardeceres memorables."
        />
      </Helmet>
      <Slider />
      <QuikActions />
      <div className="container">
        <div className="row justify-content-center mt-5">
          <h3 className="specialitiesText">{localization.title}</h3>
        </div>
        <div className="row justify-content-center">
          <Link to="/menu">
            <a className="showMore">{localization.subtitle}</a>
          </Link>
        </div>
      </div>

      <SpecialitiesSlider />
      <RRSS />
      <ActionButton />
    </Fragment>
  );
}

export default Home;
